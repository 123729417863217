export enum DEBUGGER_TAB_KEYS {
  RAG_DATASOURCE_TAB = "RAG_DATASOURCE_TAB",
  DATASOURCE_TAB = "DATASOURCE_TAB",
  RESPONSE_TAB = "RESPONSE_TAB",
  HEADER_TAB = "HEADERS_TAB",
  ERROR_TAB = "ERROR_TAB",
  LOGS_TAB = "LOGS_TAB",
  STATE_TAB = "STATE_TAB",
  VISUALIZATION_TAB = "VISUALIZATION_TAB",
}
