export default {
  INPUT_TEXT: "INPUT_TEXT",
  FIXED_KEY_INPUT: "FIXED_KEY_INPUT",
  DROP_DOWN: "DROP_DOWN",
  SEGMENTED_CONTROL: "SEGMENTED_CONTROL",
  SWITCH: "SWITCH",
  KEYVALUE_ARRAY: "KEYVALUE_ARRAY",
  FILE_PICKER: "FILE_PICKER",
  QUERY_DYNAMIC_TEXT: "QUERY_DYNAMIC_TEXT",
  QUERY_DYNAMIC_INPUT_TEXT: "QUERY_DYNAMIC_INPUT_TEXT",
  CHECKBOX: "CHECKBOX",
  NUMBER_INPUT: "NUMBER_INPUT",
  ARRAY_FIELD: "ARRAY_FIELD",
  WHERE_CLAUSE: "WHERE_CLAUSE",
  ENTITY_SELECTOR: "ENTITY_SELECTOR",
  PAGINATION: "PAGINATION",
  SORTING: "SORTING",
  PROJECTION: "PROJECTION",
  FORM_TEMPLATE: "FORM_TEMPLATE",
  MULTIPLE_FILE_PICKER: "MULTIPLE_FILE_PICKER",
  RADIO_BUTTON: "RADIO_BUTTON",
  RAG_INTEGRATIONS: "RAG_INTEGRATIONS",
  SLIDER: "SLIDER",
  RAG_DOCUMENTS_SELECTOR: "RAG_DOCUMENTS_SELECTOR",
  HYBRID_SEARCH: "HYBRID_SEARCH",
  FUNCTION_CALLING_CONFIG_FORM: "FUNCTION_CALLING_CONFIG_FORM",
  DATASOURCE_LINK: "DATASOURCE_LINK",
  CUSTOM_ACTIONS_CONFIG_FORM: "CUSTOM_ACTIONS_CONFIG_FORM",
};
